@import "../node_modules/bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Quattrocento+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
/* VARS */
$PINK_LIGHT: #f3c6c7;
$PINK_DEFAULT: #e88e8e;
$PINK_DARK: #d01d1e;
$YELLOW_LIGHT: #fdf0c1;
$YELLOW_DEFAULT: #fce183;
$YELLOW_DARK: #f0bb00;
$BLUE_LIGHT: #c4e5ea;
$BLUE_DEFAULT: #88cbd4;
$BLUE_DARK: #1297a5;
$GRAY_LIGHT: #f1f3ea;
$GRAY_DEFAULT: #e3e2d6;
$GRAY_DARK: #babba7;
$DARK_TEXT: #41423a;
$GRAY_TEXT: #9a9b8c;
$YELLOW_TEXT: #e0af06;
//
//
//GENERAL SCSS
* {
    font-family: "Quattrocento Sans", Arial, Helvetica, sans-serif;
    font-style: normal;
    box-sizing: border-box;
}
html,
body {
    height: 100%;
    font-size: 21px;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #222;
}
p{
    color: #41423a;
}
#root {
    display: flex;
    margin: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 960px;
    max-width: 960px;
    height: 480px;
    max-height: 480px;
    background: rgba(0, 0, 0, 1);
    font-size: 21px;
    overflow-x: hidden;
    border-radius: 16px;
}
//Animations
@-webkit-keyframes load6 {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
            0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
            0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em,
            -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em,
            -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em,
            -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
            0 -0.83em 0 -0.477em;
    }
}
@keyframes load6 {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
            0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
            0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em,
            -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em,
            -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em,
            -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
            0 -0.83em 0 -0.477em;
    }
}
@-webkit-keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
//
//Logo
.logo {
    img {
        width: 100%;
        height: auto;
    }
}
//
//Backgrounds
.splash {
    width: 960px;
    height: 480px;
    border-radius: 16px;
    display: grid;
    grid-template-columns: 46.5% auto 46.5%;
    grid-template-rows: auto;
    color: white;
    z-index: 9999;
    position: fixed;
    &.blue {
        background: linear-gradient(164deg, #1099ac 2.61%, #38abaf 33.81%, #9eeaed 77.48%);
    }
    &.pink {
        background-image: linear-gradient(164deg, #e04445 9.43%, #f99b45 94.15%);
    }
    img {
        grid-column: 2 / 3;
        grid-row: 2 / 2;
        width: 100%;
        animation: fadeIn 1s;
        -webkit-animation: fadeIn 1s;
        -moz-animation: fadeIn 1s;
        -o-animation: fadeIn 1s;
        -ms-animation: fadeIn 1s;
    }
    .loader {
        grid-column: 2 / 3;
        grid-row: 2 / 2;
        width: 100%;
        color: #ffffff;
        font-size: 90px;
        text-indent: -9999em;
        overflow: hidden;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        margin: 72px auto;
        position: relative;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
        animation: load6 1.7s infinite ease, round 1.7s infinite ease;
    }
}
.aurora-bg {
    width: 1180px;
    height: 480px;
    border-radius: 16px;
    display: grid;
    grid-template-columns: 275px 15px 600px 15px 275px;
    grid-template-rows: auto;
    color: white;
    margin-left: 200px;
    transition: all 0.2s ease;
    .title {
        color: white;
    }
    &.blue {
        background: linear-gradient(164deg, #1099ac 2.61%, #38abaf 33.81%, #9eeaed 77.48%);
        .aurora-btn {
            background-color: rgba($BLUE_DARK, 0.5);
        }
    }
    &.pink {
        background-image: linear-gradient(164deg, #e04445 9.43%, #f99b45 94.15%);
        .aurora-btn {
            background-color: rgba($PINK_LIGHT, 0.18);
        }
    }
    &.active {
        margin-left: -200px;
    }

    .col-right{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .col-left {
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        display: grid;
        grid-template-rows: 100px 190px 190px;
        grid-template-columns: auto 15px;
        justify-content: center;
        align-items: center;
        margin: 0;
        .logo {
            margin: 35px 40px 0 25px;
            grid-column: 1 / 2;
            grid-row: 1 / 2;
        }
        .legenda {
            margin: -30px 35px 10px 40px;
            grid-column: 1 / 2;
            grid-row: 2 / 3;
            max-height: 140px;

            display: flex;
            flex-direction: column;
            justify-items: space-between;
            align-items: flex-start;


            span {
                margin: 0;
                height: 30px;
                margin: 0;
            }
            span:before {
                content: " ";
                display: inline-block;
                vertical-align: middle;
                height: 14px;
                width: 30px;
                border: 2px solid rgba(255,255,255,0.3);
                border-radius: 3px;
                margin-right: 20px;
                margin-top:2px;
            }

            span:first-child{
                &:before {
                    background: #E04445;
                }
            }
            span:nth-child(2) {
                &:before {
                    background: #2FB3BE;
                }
            }
            span:nth-child(3) {
                &:before {
                    background: #f8cc59;
                }
            }
        }
        .alert-box {
            padding: 0px 45px 0px 30px;
            margin: 0;
            grid-column: 1 / 3;
            grid-row: 3 / 4;
            background: rgba(34, 128, 140, 0.45);
            border-bottom-left-radius: 16px;
            text-align: center;
            line-height: 1.4;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 25% auto;
            justify-items: center;
            align-items: center;
            height: 100%;
            font-weight: bold;
            color: #fff;
            img {
                grid-column: 1 / 2;
                grid-row: 1 / 2;
                width: 20%;
                margin: 0 auto;
                margin-top: -10%;
            }
            /* &::before {
                content: " ";
                background-image: url("./assets/images/warning_icon.svg");
                background-size: 100% 100%;
                height: 50px;
                width: 50px;
                color: #ffffff;
                margin: auto;
                position: relative;
                top: -1em;
            } */
            p {
                grid-column: 1 / 2;
                grid-row: 2 / 2;
                margin-top: -10%;
                color: #fff;
            }
        }
    }
    .col-main {
        grid-column: 2 / 5;
        grid-row: 1 / 2;
        background: #f5f6f1;
        border-radius: 12px;
        height: 100%;
        max-height: 480px;
        display: grid;
        grid-template-rows: 100px 190px 190px;
        grid-template-columns: auto;
        justify-items: stretch;
        align-items: center;

        box-shadow: 4px 0 50px rgba(0, 0, 0, 0.3);

        .page-title {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
            height: 100%;
            margin: 0 35px;
            color: $DARK_TEXT;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            padding-bottom: 22px;

            b {
                color: $DARK_TEXT;
                font-weight: 300;
            }

            h2 {
                font-size: 1rem;
                font-weight: normal;
                padding: 0;
                margin: 0;
            }

            .today-date {
                opacity: 0.7;
                font-size: 0.7rem;
            }
        }

        .daily-details-chart {
            height: 190px;
            grid-column: 1 / 2;
            grid-row: 2 / 3;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            .chart-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: nowrap;
                align-items: flex-start;

                .time-label {
                    display: block;
                    height: 35px;
                    width: 100%;
                    text-align: center;
                    color: #41423a;
                    opacity: 40%;
                    font-size: 0.8em;
                    padding-top: 0.5rem;
                }

                .readings-bar-chart {
                    width: 555px;
                    height: 196px;
                    align-self: center;
                    overflow-x: scroll;
                    /*
                    *:not(svg, rect){
                        pointer-events: none;
                    }*/
                }
                .last-reading {
                    align-self: center;
                    width: 150px;
                    height: 196px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    flex-wrap: nowrap;
                    align-items: flex-start;
                    margin-left: 15px;
                    color: rgba(255, 255, 255, 0.9);
                    fill: rgba(255, 255, 255, 0.9);

                    &.dark {
                        fill: rgba(0, 0, 0, 0.45);
                    }

                    .value-card {
                        padding: 0.5em 0;
                        border-radius: 6px;
                        box-shadow: 0px 0px 20px #dbd9ca;
                        text-align: center;
                        margin: auto;
                        color: #fff;
                        font-weight: 400;
                        font-size: 2.8rem;
                        /* line-height: 2rem; */
                        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
                        background-color: $BLUE_DEFAULT;
                        height: 140px;
                        width: 100%;

                        .title {
                            font-weight: normal;
                            font-size: 1rem;
                            line-height: 1rem;
                            color: #41423a;
                            bottom: 0;
                        }
                        .indicator {
                            font-weight: bold;
                            font-size: 3.2rem;
                        }
                    }
                }
            }
            &.calendar {
                justify-content: center;
                align-items: center;
            }
        }

        .daily-overview-chart {

            box-shadow: 0 -2px 40px rgba(160, 164, 153, 0.25);
            height: 100%;
            grid-column: 1 / 2;
            grid-row: 3 / 4;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;

            .chart-row{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: nowrap;
                align-items: flex-start;
                height: 100%;

                .chart-container {
                    width: 555px;
                    height: 100%;
                    overflow-x: scroll;
                    margin: 0 0 0px 0;
                    box-sizing: border-box;

                    *{
                        pointer-events: none;
                    }
                }
            }



            &.weekly {
                justify-content: space-between;
                align-items: start;
                overflow: visible;
                box-shadow: none;
                background: transparent;

                *:nth-child(1) {
                    z-index: 3;
                }
                *:nth-child(2) {
                    z-index: 2;
                }
                *:nth-child(2) {
                    z-index: 1;
                }
            }
            &.calendar {
                justify-content: space-between;
                align-items: flex-end;
                box-shadow: none;
                .calendar-button {
                    padding: 15px;
                    border-radius: 20px;
                    box-shadow: 0px 0px 18px rgba(160, 164, 153, 0.25);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 49%;
                    height: 50%;
                    cursor: pointer;
                    background-color: #ffffff;
                    &:active {
                        background-color: $BLUE_LIGHT;
                        .text {
                            color: #ffffff;
                        }
                    }
                    .text {
                        font-weight: bold;
                        font-size: 21px;
                        line-height: 20px;
                        color: $GRAY_TEXT;
                        margin: 0;
                    }
                }
            }
        }
    }
}
//
//Buttons
.aurora-btn {
    font-weight: bold !important;
    font-size: 3em !important;
    min-height: 1.3em;
    width: 100%;
    border-radius: 8px;
    border: none;
    color: #ffffff;
}
.menu-list {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin-left: 30px;
    margin-top: 20px;
    font-size: 1.2rem;
    font-weight: bold;
    color: $GRAY_LIGHT;
    height: 200px;
    width: 85%;

    &.open{
        li{
            opacity: 1;
        }
    }

    li {
        margin: 5px 0;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0 20px;
        opacity: 0;

        p{
            display: block;
            margin: 0;
            pointer-events: none;
            letter-spacing: 0.05em;
            color: #fff;
        }

        &.menu-title{
            p{
                margin: 0 0 30px 0;
                color: rgba(0,0,0,0.6);
            }
        }
    }
}
.menu-toggle {
    display: inline-grid;
    margin: 40px 0 0 5px;
    min-height: 55px;
    min-width: 55px;
    transition: all 0.3s ease;
    background: transparent;
    border: none;
    padding: 12px;
    *{
        pointer-events: none;
    }
    span {
        width: 100%;
        height: 4px;
        background-color: white;
        margin-bottom: 5px;
        transform-origin: center;
        transition: all 0.3s ease;
    }
    &.active {

        margin-left: 215px;
        span:first-child {
            transform-origin: center;
            -webkit-transform: rotate(45deg) translate(8px, 8px);
            transform: rotate(45deg) translate(8px, 8px);
        }
        span:nth-child(2) {
            opacity: 0;
        }
        span:last-child {
            transform-origin: center;
            -webkit-transform: rotate(-45deg) translate(3px, -12px);
            transform: rotate(-45deg) translate(6px, -6px);
        }
    }
}
//
//Inputs
.aurora-input {
    font-weight: bold;
    font-size: 1em;
    line-height: 24px;
    border: 0.5px solid #ffffff;
    box-sizing: border-box;
    border-radius: 6px;
    color: #ffffff;
    background-color: transparent;
    .input-group-text {
        background-color: inherit;
        border: none;
        color: inherit;
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
        padding-right: 0;
    }
    .form-control {
        background-color: inherit;
        border: none;
        color: inherit;
        font-weight: normal;
        font-size: inherit;
        line-height: inherit;
    }
    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
}
//
//Chart
.chart-container {
    transition: all 0.3s ease;
    &.active {
        width: 50%;
    }
    /*
    .chart-row {
        font-size: 18px;
        line-height: 24px;
        padding: 3em 0 0 0;
        min-height: 50%;
        .title {
            color: #41423a !important;
            margin-left: 30px;
        }
    }*/
    .card-row {
        position: absolute;
        bottom: 0;
        width: inherit;
        @media (max-width: 768px) {
            position: relative;
            margin: auto;
        }
    }
    #text {
        width: 120px;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;

        /* Position the tooltip */
        position: absolute;
        z-index: 1;
    }
    @media (max-width: 768px) {
        width: 100%;
        margin: 3em 0px;
    }
}

.axisText {
    /*&.axisX .tick:nth-child(odd) {
        display: none;
    }*/
    text,
    p {
        fill: #b5b6a4;
        color: #b5b6a4 !important;
        font-weight: bold;
        font-size: 0.8rem;
        margin-top: 2.8em;
    }
    path {
        display: none;
    }
}

.chartRect {
    width: 0.95em;
    height: 0.65em;
    @media (max-width: 1028px) {
        width: 0.95em;
    }
    @media (max-width: 559px) {
        width: 0.95em;
    }
    @media (max-width: 419px) {
        width: 0.95em;
    }
}

.chartDot {
    width: 0.2em;
    height: 0.2em;
    border-radius: 100%;
    transform: translate(1px, 1px);
    @media (max-width: 1028px) {
        width: 0.2em;
    }
    @media (max-width: 559px) {
        width: 0.2em;
    }
    @media (max-width: 419px) {
        width: 0.2em;
    }
}
/*
.chartBgRect {
    position: relative;
    width: 100%;
    transform: translate(1px, 1px);
    
    &.bg {
        top: 0;
        height: 70%;
    }
    
    &.middle {
        bottom: 50;
        height: 25%;
    }
}*/
.chartIcon {
    height: 30px;
    width: 30px;
    transform: translate(-5px, -15px);
    line {
        stroke: rgb(0, 0, 0);
        stroke-width: 2;
    }
}

#chartTooltipRect {
    position: absolute;
    width: auto;
    min-width: 60px;
    height: 40px;
    background-color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 15px;
    transform: translate(0, -45px);
    box-shadow: 0 2px 4px rgba(160, 164, 153, 0.5);
    display: none;
    margin-top:-180px;

    &.visible{
        display: block;
    }
    &::before {
        content: "\A";
        position: absolute;
        border-top-color: inherit;
        border-width: 3px 6px 3px 0;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid;
        bottom: -10px;
        left: calc(30% + 2px);
    }
    span {
        color: #ffffff;
    }
}
#recentBarChart {
    height: 205px;
    /*overflow-x: scroll;*/
    margin-top: -15px;
    line {
        stroke-width: 0.1rem;
        stroke: $GRAY_DEFAULT;
    }
    .axisX .tick {
        display: none;
        &:nth-child(8n),
        &.last {
            display: block;
        }
    }

    svg{
        text{
            pointer-events: none;
        }
    }
}
#recent-dotted-chart {
    svg{
        *{
            pointer-events: none;
        }
    }
    /*
    .dotChartX {
        .tick {
            width: 30px;
            color: $DARK_TEXT;
            line {
                stroke: #fff;
            }
            text {
                font-size: 0.6rem;
                color: $DARK_TEXT;
                margin-top: 5px;
                opacity: 0;
            }
        }
        .tick:first-child,
        .tick:nth-child(2n) {
            text {
                opacity: 0.4;
            }
        }
    }
    
    .axisY .tick rect {
        height: 2em;
        width: 3em;
    }
    .axisX line {
        display: none;
    }
    .axisX .tick:nth-child(4n) {
        display: none;
    }
    line {
        stroke-width: 0.5%;
        stroke: $GRAY_DEFAULT;
    }
    .grid .tick {
        stroke-opacity: 0.7;
        shape-rendering: crispEdges;
    }
    .grid g {
        display: none;
    }
    .grid g:nth-child(10n) {
        display: block;
    }
    .grid g:nth-child(10n) line {
        display: block;
        stroke: #fff !important;
    }
    .grid path {
        stroke-width: 0;
    }
    
    .axisX g {
        display: none;
    }
    .axisX g:nth-child(10n) {
        display: block;
    }
    */

    .grid .domain, .grid path{
        display: none;
    }

    .axisX {
        .tick text, .tick line {
            display: none;
            color: $DARK_TEXT;
        }

        .tick:nth-child(n) text {
            display: block;
        }
    }

    .axisY .tick text {
        font-size: 12px;
    }

    #chartTooltipRect{
        &:after {
            content: "\A";
            position: absolute;
            height: 150px;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border: 1px dotted;
            left: 50%;
            top: 100%;
        }
    }
}
#d3demoblocked {
    /* width: 100%; */
    margin-top: -15px;
    .axisX .tick {
        display: none;
        &:nth-child(2n) {
            display: block;
        }
    }
}
//
//Cards
.aurora-card {
    padding: 15px;
    border-radius: 20px;
    box-shadow: 0px 0px 18px rgba(160, 164, 153, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 32.6%;
    height: 100%;
    p {
        display: block;
        text-align: center;
    }
    .card-title,
    .card-subtitle {
        font-weight: normal;
        font-size: 21px;
        line-height: 21px;
        color: #41423a;
        margin: 0px 0 20px 0;
    }
    .card-subtitle {
        color: $GRAY_TEXT;
        margin: 5px 0 0 0;
        opacity: 0.6;
        font-size: 16px;
    }
    .indicator {
        font-weight: bold;
        font-size: 52px;
        line-height: 52px;
        color: $GRAY_TEXT;
        margin: 0;
        &.pink {
            color: #e04445;
        }
        &.yellow {
            color: $YELLOW_TEXT;
        }
    }
}
.day-strip-card {
    padding: 1em 0;
    border-radius: 20px;
    box-shadow: 0px 0px 20px #dbd9ca;
    text-align: center;
    margin: auto;
    color: #fff;
    font-weight: normal;
    font-size: 68px;
    line-height: 16px;
    .title {
        font-weight: normal;
        font-size: 18px;
        line-height: 16px;
        color: #41423a;
        bottom: 0;
    }
    .indicator {
        font-weight: bold;
        font-size: 52px;
        line-height: 18px;
    }
}
//
//CALENDAR
.calendar-pagination {
    .calendar-icon {
        margin: 0px 30px;
        width: 1.8em;
    }
    .right {
        transform: rotate(180deg);
    }
}
.react-calendar {
    display: flex;
    flex-direction: column;
    width: 20em;
    justify-content: center;
    align-items: center;
    .react-calendar__viewContainer {
        padding: 20px 35px 20px 38px;
        background-color: #e3e2d6;
        width: calc(100% - 84px);
        border-bottom-left-radius: 5%;
        border-bottom-right-radius: 5%;
        .react-calendar__tile {
            border-radius: 100%;
            border: none;
            background-color: $GRAY_LIGHT;
            color: $DARK_TEXT;
            margin: 3px;
            font-size: 16px;
            line-height: 24px;
            align-items: center;
            font-weight: 400;
            max-width: 30px !important;
            max-height: 30px;
            padding-top: 3px;
            &:disabled {
                opacity: 0.5;
            }
        }
        .react-calendar__tile--now,
        .react-calendar__tile--active {
            background-color: $BLUE_DARK;
            color: #ffffff;
        }
        .react-calendar__month-view__days__day--neighboringMonth {
            background-color: $GRAY_LIGHT;
            color: $GRAY_DARK;
        }
        .react-calendar__tile--range {
            background-color: $BLUE_DEFAULT;
            color: #ffffff;
        }
    }
    .react-calendar__navigation {
        width: inherit;
        span {
            font-weight: bold;
            font-size: 24px;
            line-height: 24px;
        }
        .react-calendar__navigation__arrow {
            width: 0;
            height: 0;
            border: none;
            border-top: 30px solid transparent;
            border-bottom: 30px solid transparent;
            border-right: 30px solid #babba7;
            background-color: transparent;
            color: transparent;
            &:disabled {
                opacity: 0.3;
            }
        }
        .react-calendar__navigation__prev-button {
            left: -55px;
            position: relative;
        }
        .react-calendar__navigation__next-button {
            right: -55px;
            position: relative;
            transform: rotate(180deg);
        }
        .react-calendar__navigation__label {
            padding: 20px 0px;
            color: $BLUE_DARK;
            border: none;
            border-top-left-radius: 25px;
            border-top-right-radius: 25px;
            width: inherit;
        }
    }
    //REMOVE LABELS DOS DIAS DA SEMANA
    .react-calendar__month-view__weekdays {
        display: none !important;
    }
}
